import * as React from 'react'
import { useQueries } from '@tanstack/react-query'
import { VehicleId } from '@api/endpoints'
import { getVehicleOptionsById, VehicleOptionResponse } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetVehicleOptionsById } from './queryKeys'

export type VehicleOptionResponseWithVehicleId = {
  vehicleId: VehicleId
  data: VehicleOptionResponse
}

export const useQueryGetVehicleOptionsByIds = (
  vehicleId: ReadonlyArray<VehicleId>
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) /*: QueriesResults<Array<VehicleOptionResponseWithVehicleId>>*/ => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()

  const query = useQueries({
    queries: vehicleId.map((id) => ({
      queryKey: queryKeyGetVehicleOptionsById(id),
      queryFn: () => getVehicleOptionsById(id)
      //transform: (data: VehicleOptionResponse) => ({ data: data, vehicleId: id })
    })),
    combine: (results) =>
      results.map((result, index) => ({
        ...result,
        pending: results.some((result) => result.isPending),
        data: {
          vehicleId: vehicleId[index],
          options: result.data
        }
      }))
  })

  React.useEffect(() => {
    query.forEach((q) => {
      if (q.error) {
        errorHandler(q.error)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return query
}
