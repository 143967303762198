import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { formatNumberInEstonian } from '@model/language'
import { VehicleId } from '@api/endpoints'
import {
  useFindFuelType,
  useFindTransmissionType,
  useFindVehicleColor,
  useFindVehicleVariant,
  useFindWheelDrive
} from '@hooks/Translation/TranslatedEntity'
import { useTranslateOptionalValue } from '@hooks/Translation/TranslatedValue'
import { useQueryGetVehicleById } from '@hooks/ReactQuery/vehicles/useQueryGetVehicleById'
import { Grid } from '@mui/material'
import styles from './MainDataVehicle.module.css'

type Props = Readonly<{
  vehicleId: VehicleId
}>

export const MainDataVehicle: React.FC<Props> = ({ vehicleId }) => {
  const { t } = useTranslation()

  const getVehicle = useQueryGetVehicleById(vehicleId)
  const mainData = getVehicle.data?.mainData

  const translateOptionalValue = useTranslateOptionalValue()
  const vehicleVariant = translateOptionalValue(useFindVehicleVariant(mainData?.variant?.value))
  const transmissionType = translateOptionalValue(useFindTransmissionType(mainData?.transmission?.value))
  const wheelDrive = translateOptionalValue(useFindWheelDrive(mainData?.wheelDrive?.value))
  const fuelType = translateOptionalValue(useFindFuelType(mainData?.fuel?.value))
  const vehicleColor = translateOptionalValue(useFindVehicleColor(mainData?.color?.value))

  return (
    <div className={styles.mainDataContainer}>
      {mainData && (
        <Grid>
          <p>{vehicleVariant}</p>
          <p>{mainData.year}</p>
          {mainData.mileage && (
            <p>
              {t('components.cards.vehicle.odometer-reading', {
                odometerReading: formatNumberInEstonian(mainData.mileage)
              })}
            </p>
          )}
          <p>{transmissionType}</p>
          <p>{wheelDrive}</p>
          {fuelType && (
            <p>
              {`${t('components.cards.vehicle.engine-capacity', {
                engineCapacity: mainData.engineDisplacement
              })} / ${fuelType} / ${t('components.cards.vehicle.engine-power', {
                enginePower: mainData.powerInKw
              })} `}
            </p>
          )}
          <p>{vehicleColor}</p>
          {mainData.registrationNumber ? <p>{mainData.registrationNumber}</p> : <p>&nbsp;</p>}
          <Grid className="inner-container column" item>
            <p>
              {t('components.cards.vehicle.fuel-consumption-types.city') +
                ' / ' +
                t('components.cards.vehicle.fuel-consumption-types.highway') +
                ' / ' +
                t('components.cards.vehicle.fuel-consumption-types.average')}
            </p>
            <p>
              {`${t('components.cards.vehicle.fuel-consumption', {
                fuelConsumption: mainData.fuelConsumptionCity
              })} / ${t('components.cards.vehicle.fuel-consumption', {
                fuelConsumption: mainData.fuelConsumptionHighway
              })}  / ${t('components.cards.vehicle.fuel-consumption', {
                fuelConsumption: mainData.fuelConsumptionAverage
              })} ${t('components.cards.vehicle.fuel-consumption-types.period')}`}
            </p>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
