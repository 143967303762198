import * as React from 'react'
import { Page, PageContainerWithHead, PageContent } from '@layout/Page'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@hooks/useTheme'
import { useLanguage } from '@hooks/useLanguage'
import { DealerContacts } from '@layout/DealerContacts'
import { ContactForm } from '@forms/ContactForm'
import { VehiclesForComparison } from './VehiclesForComparison'

export const ComparisonPage: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const pageId = React.useId()

  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))

  const breadCrumbs = React.useMemo(
    () => [{ to: routes.Index, title: t('pages.main.title') }, { title: t('pages.comparison.title') }],
    [t, routes]
  )
  return (
    <Page id={pageId}>
      <PageContainerWithHead title={t('pages.comparison.title')} breadCrumbs={breadCrumbs}>
        {isDownMd ? (
          <PageContent subtitle={t('pages.comparison.noSmallScreenSupported')}>
            <ContactForm topic="other" />
            <DealerContacts />
          </PageContent>
        ) : (
          <VehiclesForComparison />
        )}
      </PageContainerWithHead>
    </Page>
  )
}
