import * as React from 'react'
import { VehicleId } from '@api/endpoints'
import { createVehiclesShort } from '@api/endpoints/vehicles'
import { useVehiclesIdCompareStore } from '@stores/useVehiclesIdCompareStore'
import { useQueryGetVehicleById } from '@hooks/ReactQuery/vehicles/useQueryGetVehicleById'
import { ButtonClose } from '@controls/buttons'
import { VehicleCard } from '@layout/VehicleCard'
import styles from './VehicleCard.module.css'

type Props = Readonly<{
  vehicleId: VehicleId
}>

export const ComparisonVehicleCard: React.FC<Props> = ({ vehicleId }) => {
  const removeVehicle = useVehiclesIdCompareStore((s) => s.removeVehicle)

  const getVehicle = useQueryGetVehicleById(vehicleId)

  return getVehicle.data ? (
    <VehicleCard className={styles.comparisonVehicleCard} vehicle={createVehiclesShort(getVehicle.data)}>
      <ButtonClose variant="text" className={styles.closeBtn} onClick={() => removeVehicle(vehicleId)} />
    </VehicleCard>
  ) : null
}
