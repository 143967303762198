/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import clsx from 'clsx'
import { VehicleId } from '@api/endpoints'
import { TranslatedOptionGroup, TranslatedValue } from '@api/endpoints/translations/types'
import SvgDownArrow from '@assets/icons/downArrow.svg?react'
import SvgLine from '@assets/icons/line.svg?react'
import SvgUpArrow from '@assets/icons/upArrow.svg?react'
import { Button } from '@mui/material'
import { useTranslateValue } from '@hooks/Translation/TranslatedValue'
import { useVehiclesIdCompareStore } from '@stores/useVehiclesIdCompareStore'
import { FlexContainer } from '@layout/FlexContainer'
import styles from './VehiclesForComparison.module.css'

type Props = Readonly<{
  objTitle: TranslatedOptionGroup
  vehicleNotToShow: 0 | 1
  options: Map<VehicleId, ReadonlyArray<TranslatedValue>>
}>

export const VehicleOptionsFragment: React.FC<Props> = ({ objTitle, vehicleNotToShow, options }) => {
  const translateValue = useTranslateValue()

  const [showOptions, setShowOptions] = React.useState(false)

  const vehicleIds = useVehiclesIdCompareStore((state) => state.vehicles.map((v) => v.id))

  return (
    <React.Fragment>
      <div className={styles.bottomSection}>
        <div className={styles.leftSide}>
          <Button
            variant="text"
            onClick={() => setShowOptions((s) => !s)}
            endIcon={showOptions ? <SvgUpArrow /> : <SvgDownArrow />}
          >
            {translateValue(objTitle)}
          </Button>
        </div>
        {showOptions && (
          <FlexContainer
            fixedDirection
            className={clsx(styles.rightSide, { rightSideTwoOrMoreItems: vehicleIds.length >= 2 })}
          >
            {vehicleIds.slice(vehicleNotToShow).map((vehicleId, index) => {
              const optionValues = options.get(vehicleId)

              return optionValues ? (
                <ul key={`vehicle-compare-safety${index}`} className={styles.vehicleOptionsContainer}>
                  {optionValues.map((item, i) => (
                    <li key={`option-${i}`}>{translateValue(item)}</li>
                  ))}
                </ul>
              ) : null
            })}
            {/* TODO: Why this hidden div needed? [milana] */}
            {vehicleIds.length === 1 && <div className={clsx(styles.addContainer, styles.hidden)}></div>}
          </FlexContainer>
        )}
      </div>
      <SvgLine />
    </React.Fragment>
  )
}
