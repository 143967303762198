import * as React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { hasValue } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { TranslatedValue, VehicleOptionGroupTranslationId } from '@api/endpoints/translations'
import { Grid, useMediaQuery } from '@mui/material'
import { useVehiclesIdCompareStore } from '@stores/useVehiclesIdCompareStore'
import { useTheme } from '@hooks/useTheme'
import { useLanguage } from '@hooks/useLanguage'
import { useQueryGetOptionGroups } from '@hooks/ReactQuery/translations/useQueryGetOptionGroups'
import { useQueryGetVehicleOptionsByIds } from '@hooks/ReactQuery/vehicles/useQueryGetVehicleOptionsByIds'
import { RouterLink } from '@controls/buttons'
import { PageContent } from '@layout/Page'
import { FlexContainer } from '@layout/FlexContainer'
import { MainDataVehicle } from '../MainDataVehicle'
import { ComparisonVehicleCard } from '../VehicleCard'
import { VehicleOptionsFragment } from './VehicleOptionsFragment'
import styles from './VehiclesForComparison.module.css'

export const VehiclesForComparison: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()

  const { currentTheme } = useTheme()
  const isDownLg = useMediaQuery(currentTheme.breakpoints.down('lg'))

  const vehicleIds = useVehiclesIdCompareStore((state) => state.vehicles.map((v) => v.id))
  const [vehicleNotToShow, setVehicleNotToShow] = React.useState<0 | 1>(0)

  const getOptionGroups = useQueryGetOptionGroups()
  const getVehicleOptionsByIds = useQueryGetVehicleOptionsByIds(vehicleIds)

  const getOptionsForGroup = (
    optionId: VehicleOptionGroupTranslationId
  ): Map<VehicleId, ReadonlyArray<TranslatedValue>> | undefined => {
    const r = getVehicleOptionsByIds
      .map((result) =>
        result.data
          ? {
              vehicleId: result.data.vehicleId,
              itemResponses:
                result.data.options?.find((obj) => obj.optionGroup?.value === optionId)?.itemResponses ?? []
            }
          : undefined
      )
      .filter(hasValue)

    if (r.length > 0 && r.some((v) => (v?.itemResponses.length ?? 0) > 0)) {
      //console.log(r.some(v => v?.itemResponses.length > 0)
      const map = new Map<VehicleId, ReadonlyArray<TranslatedValue>>()
      r.forEach((v) => {
        if (v) {
          map.set(v.vehicleId, v.itemResponses)
        }
      })
      return map.size > 0 ? map : undefined
    } else {
      return undefined
    }
  }

  React.useEffect(() => {
    if (isDownLg && vehicleIds.length > 2) {
      setVehicleNotToShow(1)
    } else {
      setVehicleNotToShow(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownLg])

  return (
    <PageContent className={styles.vehiclesForComparisonContainer}>
      {vehicleIds.length === 0 ? (
        <RouterLink className={styles.noCars} to={routes.Catalog}>
          {t('pages.comparison.noCarsForComparison')}
        </RouterLink>
      ) : (
        <>
          {vehicleIds.length === 2 && (
            <div className={styles.add}>
              <RouterLink to={routes.Catalog}>{t('pages.comparison.addVehicle')}</RouterLink>
            </div>
          )}
          <div className={styles.top}>
            <div className={styles.leftSide}></div>
            <FlexContainer
              fixedDirection
              className={clsx(styles.rightSide, { rightSideTwoOrMoreItems: vehicleIds.length >= 2 })}
            >
              {vehicleIds.slice(vehicleNotToShow).map((vehicleId, index) => (
                <ComparisonVehicleCard key={`vehicle-compare-${index}`} vehicleId={vehicleId} />
              ))}
              {vehicleIds.length === 1 && (
                <FlexContainer justifyContentCenter alignItemsCenter className={styles.addContainer}>
                  <RouterLink to={routes.Catalog}>{t('pages.comparison.addVehicle')}</RouterLink>
                </FlexContainer>
              )}
            </FlexContainer>
          </div>
          <h3>{t('pages.comparison.mainCharacteristics')}</h3>
          <div className={styles.main}>
            <div className={styles.leftSide}>
              <Grid>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.body-type')}</h5>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.year')}</h5>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.mileage')}</h5>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.gearbox-type')}</h5>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.transmission-type')}</h5>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.engine')}</h5>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.color')}</h5>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.reg-number')}</h5>
                <h5 className={styles.titleCompare}>{t('components.cards.vehicle.headers.fuel-consumption')}</h5>
              </Grid>
            </div>
            <FlexContainer
              fixedDirection
              className={clsx(styles.rightSide, { rightSideTwoOrMoreItems: vehicleIds.length >= 2 })}
            >
              {vehicleIds.slice(vehicleNotToShow).map((vehicleId, index) => (
                <MainDataVehicle key={`vehicle-compare-main-data${index}`} vehicleId={vehicleId} />
              ))}
            </FlexContainer>
          </div>

          <h3>{t('pages.comparison.vehicleEquipment')}</h3>
          <div className={styles.bottom}>
            {getOptionGroups.data?.map((objTitle) => {
              const options = getOptionsForGroup(objTitle.id)
              return options ? (
                <VehicleOptionsFragment
                  key={objTitle.id}
                  objTitle={objTitle}
                  options={options}
                  vehicleNotToShow={vehicleNotToShow}
                />
              ) : null
            })}
          </div>
        </>
      )}
    </PageContent>
  )
}
